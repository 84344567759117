import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Loader from './components/Loader';
import Message from './components/Message';
import 'animate.css'
import './common-libs/style.scss';
import './App.css';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path={'/:pollId'} element={<Loader/>}/>
				<Route path={'*'} element={<Message msg={'Error 404'}/>}/>
			</Routes>
		</BrowserRouter>
	)
}

export default App;
