import React, {useEffect, useState} from 'react';
import io from 'socket.io-client';
import ResourcePreloader from '../services/resource-preloader';
import PollScreen from '../common-libs/poll/PollScreen';
import Screen from '../common-libs/components/Screen';
import WatermarkContext from '../common-libs/context/WatermarkContext';

export default function AppScreen(props) {
	const poll = props.poll;
	const [question, setQuestion] = useState(null);
	
	useEffect(() => {
		
		if (poll.status !== 'running') {
			return;
		}
		
		const client = io(`${poll.serverUrl}/${poll._id}`, {
			transports: ['websocket']
		});
		client.on('cmd', msg => (msg && msg._id) ? setQuestion(msg) : setQuestion(null));
		client.on('stop', () => window.location.reload());
		
		const preloader = new ResourcePreloader(poll);
		return () => {
			client.disconnect();
			preloader.destroy();
		}
	}, [poll]);
	
	return (
		<WatermarkContext.Provider value={false}>
			{question ?
				<PollScreen poll={question}/> :
				<Screen bgImg={poll.poster}/>
			}
		</WatermarkContext.Provider>
	);
}
