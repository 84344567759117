import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import AppScreen from './AppScreen';
import Message from './Message';

function Loader() {
	const {pollId} = useParams();
	const [poll, setPoll] = useState(null);
	const [loading, setLoading] = useState(true);
	const [message, setMessage] = useState('Loading...');
	
	useEffect(() => {
		if (loading) {
			let to;
			setLoading(false);
			
			(async () => {
				try {
					const url = `${process.env.REACT_APP_API_SERVER}/${pollId}`;
					const response = await fetch(url);
					const poll = await response.json();
					
					if (poll.error) {
						return setMessage(poll.error);
					}
					
					setPoll(poll);
					setMessage(null);
					
					if (poll.status !== 'running') {
						to = setTimeout(() => setLoading(true), 3000);
					}
					
				} catch (e) {
					console.log(e);
					setMessage('Ooops! Something went wrong');
				}
			})();
			
			return () => clearTimeout(to);
		}
	}, [loading, pollId]);

	return (
		<>
			{message && <Message msg={message}/>}
			{(poll && poll.serverUrl) && <AppScreen poll={poll}/>}
		</>
	)
}

export default Loader;
