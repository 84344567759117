import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import Chart from './Chart';

export default function VerticalBarsChart(props) {
	
	const create = useCallback(() => {
		const divId = props.divId;
		
		am5.array.each(am5.registry.rootElements, function (root) {
			if (root.dom.id === divId) {
				root.dispose();
			}
		});
		
		const root = am5.Root.new(divId);
		root.setThemes([
			am5themes_Animated.new(root)
		]);
		
		const chart = root.container.children.push(am5xy.XYChart.new(root, {
			panX: false,
			panY: false,
			wheelX: 'none',
			wheelY: 'none'
		}));
		
		chart.setAll({
			paddingTop: 55,
			paddingBottom: 55
		});
		chart.zoomOutButton.set('forceHidden', true);
		
		const xRenderer = am5xy.AxisRendererX.new(root, {minGridDistance: 30});
		const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
			categoryField: 'text',
			renderer: xRenderer,
			maxDeviation: 0.3
		}));
		xRenderer.grid.template.set('visible', false);
		xRenderer.labels.template.setAll({
			fontSize: 25,
			text: '{category}'
		});
		
		const yRenderer = am5xy.AxisRendererY.new(root, {});
		const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
			renderer: yRenderer,
			min: 0,
			maxDeviation: 0.3
		}));
		
		const series = chart.series.push(am5xy.ColumnSeries.new(root, {
			xAxis: xAxis,
			yAxis: yAxis,
			valueYField: 'votes',
			categoryXField: 'text'
		}));
		
		series.columns.template.setAll({
			strokeOpacity: 0,
			templateField: 'styleSettings'
		});
		
		series.bullets.push(function () {
			return am5.Bullet.new(root, {
				sprite: am5.Label.new(root, {
					text: '[{textColor}]{votesPercent}%[/]',
					centerY: am5.p50,
					centerX: am5.p50,
					populateText: true,
					fontSize: 20
				})
			});
		});
		
		return chart;
	}, [props.divId]);

	const onUpdate = (chart, data) => {
		chart.current.xAxes.values[0].data.setAll(data);
	}
	
	return <Chart
		createFunc={create}
		onUpdate={onUpdate}
		data={props.data}
		divId={props.divId}
	/>
}

VerticalBarsChart.propTypes = {
	data: PropTypes.array.isRequired,
	divId: PropTypes.string
};
