import React from 'react';
import PropTypes from 'prop-types';

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

export default function Counter({
	                                fontSize = 50,
	                                bgColor = '#000',
	                                borderColor = '#000',
	                                borderSize = 0,
	                                className = '',
	                                color = '#fff',
	                                margin = 5,
	                                padding = 5,
	                                plural = 'votes',
	                                singular = 'vote',
	                                value = 0
                                }) {
	return (
		<div className={`${className} counter`} css={css`background-color: ${bgColor};
			border-color: ${borderColor};
			border-style: solid;
			border-width: ${borderSize}px;
			color: ${color};
			font-size: ${fontSize}px;
			margin: ${margin}px;
			padding: ${padding}px;`}>
			{value} {value === 1 ? singular : plural}
		</div>
	);
}

Counter.propTypes = {
	fontSize: PropTypes.number,
	bgColor: PropTypes.string,
	borderColor: PropTypes.string,
	borderSize: PropTypes.number,
	className: PropTypes.string,
	color: PropTypes.string,
	margin: PropTypes.number,
	padding: PropTypes.number,
	plural: PropTypes.string,
	singular: PropTypes.string,
	value: PropTypes.number
}
