export function getBackgroundImageStyles(img) {
	if (!img || !img.src) {
		return {};
	}
	return {
		backgroundImage: `url(${img.src})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	}
}

export function getColor(value) {
	return value.error ? value.raw : `#${value.hex}`;
}