import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import Screen from '../components/Screen';
import VerticalBarsChart from './charts/VerticalBarsChart';
import SimplePieChart from './charts/SimplePieChart';
import Counter from '../components/Counter';

export default function PollScreen({poll, divId = 'chartdiv'}) {
	const counter = poll.counter || {};
	
	return <Screen
		className={`poll ${poll.cssClass ? poll.cssClass : ''}`}
		bgColor={poll.bgColor}
		bgImg={poll.background}
	>
		{(poll && poll.customCss) && <style>{poll.customCss}</style>}
		<div className={`root h${poll.chartPosition}`}>
			<div className={`chart`}>
				{(!poll.empty && poll.chartType === 'bars') && <VerticalBarsChart data={poll.options} divId={divId}/>}
				{(!poll.empty && poll.chartType === 'pie') && <SimplePieChart data={poll.options} divId={divId}/>}
			</div>
			{(poll.counter && poll.counter.display) && <Counter className={`counter v${counter.vPosition} h${counter.hPosition}`} value={poll.total} {...counter}/>}
		</div>
	</Screen>
}

PollScreen.propTypes = {
	divId: PropTypes.string,
	poll: PropTypes.object.isRequired
};
