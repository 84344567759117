import React from 'react';
import PropTypes from 'prop-types';
import {getBackgroundImageStyles} from '../utils/Styles';
import WaterMark from './Watermark';

export default function Screen({bgColor = '#fff', bgImg = {}, className = '', ...props}) {
	return (
		<div className={`app-screen ${className}`} style={{
			...getBackgroundImageStyles(bgImg),
			backgroundColor: bgColor
		}}>
			<WaterMark position={'top-right'}/>
			{props.children}
		</div>
	)
}

Screen.propTypes = {
	bgColor: PropTypes.string,
	bgImg: PropTypes.object,
	className: PropTypes.string
}
