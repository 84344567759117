const loaded = [];
const queue = [];

export default class ResourcePreloader {
	
	interval;
	
	/**
	 * @param poll
	 */
	constructor(poll) {
		if (poll.preload) {
			poll.preload.forEach(src => src && queue.push(src));
		}
		
		this.interval = setInterval(this.loadNext, 1000);
	}
	
	loadNext() {
		if (!queue.length) {
			return;
		}
		
		const src = queue.shift();
		if (loaded.includes(src)) {
			return;
		}
		loaded.push(src);
		
		const preloadLink = document.createElement('link');
		preloadLink.href = src;
		preloadLink.rel = 'preload';
		preloadLink.as = 'image';
		document.head.appendChild(preloadLink);
	}
	
	destroy() {
		clearInterval(this.interval);
		loaded.length = 0;
		queue.length = 0;
	}
}