import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import * as am5 from '@amcharts/amcharts5';

export default function Chart({divId = 'chartdiv', createFunc, data, onUpdate}) {
	const chart = useRef(null);
	
	useEffect(() => {
		chart.current = createFunc();
		return () => {
			chart.current.dispose();
		}
	}, [divId, createFunc]);
	
	useEffect(() => {
		if (!chart || !chart.current) {
			return;
		}
		
		let total = 0;
		data.forEach(d => total += +d.votes);
		const newData = data.map(d => {
			const row = {...d};
			row.votesPercent = total > 0 ? Math.round(d.votes / total * 100) : 0;
			row.styleSettings = {fill: am5.color(row.seriesColor)};
			return row;
		});
		
		chart.current.series.values[0].data.setAll(newData);
		
		onUpdate && onUpdate(chart, newData);
		
	}, [data, onUpdate]);
	
	return <div id={divId} className={'amcharts5-wrap'}></div>
}

Chart.propTypes = {
	createFunc: PropTypes.func.isRequired,
	data: PropTypes.array.isRequired,
	divId: PropTypes.string,
	onUpdate: PropTypes.func
}
